import request from '@/utils/requestV2'
const qs = require('qs')

// 获取站点对应的资源个数
export function getStationResourceQuantity (data) {
  return request({
    url: '/ooh-product/v1/productresource/getstationresourcequantity',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 根据资源ID查询特定单个资源
export function getResourceById (data) {
  return request({
    url: '/ooh-product/v1/productresource/get',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 批量查询资源
export function getResourcePage (data) {
  return request({
    url: '/ooh-product/v1/productresource/getresourcepage',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 获取资产下的站点集合按照站点级别分组
export function getStationProduct (data) {
  return request({
    url: '/ooh-product/v1/productresource/getstationproduct',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 产品资源分页
export function getProductResourcePage (data) {
  return request({
    url: '/ooh-product/v1/productresource/getproductresourcepage',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 发起下载excel数据通过条件查询产品资源
export function startDownloadResources (data) {
  return request({
    url: '/ooh-product/v1/productresource/startdownloadresources',
    method: 'get',
    params: data
  })
}
