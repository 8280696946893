<template>
    <Modal v-model="modalEditSchedule" title="编辑发布档期" width="800">
        <Row>
            <i-col span="24">
                档期类型：
                <RadioGroup v-model="standard">
                    <Radio v-for="item in scheduleTypes" :key="item.id" :label="item.id">{{item.name}}</Radio>
                </RadioGroup>
            </i-col>
            <p class="remark"  v-if="standard===2">(注： 如果您选择的日期破坏了常规档期售卖，我们将收取破档费。 提示： 自定义日期，是以连续档期来设置点位)</p>
        </Row>
        <Row  v-if="standard===1" class="p-t-10">
          <i-col span="24">
            <CheckboxGroup v-model="chooseSchedules">
                <Checkbox class="m-t-5" v-for="(item,index) in schedules" :key="index" :label="item.value" border>{{item.label}}</Checkbox>
            </CheckboxGroup>
            <p class="remark m-t-5">tips: “X月上”，代表投放档期为X月1日-X月14日；“X月下”，代表投放档期为X月15日-X月28日。</p>
          </i-col>
        </Row>
        <div v-else class="p-t-10">
          <Row v-for="(item,index) in customSchduleList" :key="index" class="p-b-5">
            <i-col span="8">
              <p>
                <span class="title">开始日期</span> <DatePicker size="small" transfer class="text-black" v-model="item.startDate" :editable="false" type="date"
                  style="width: 150px" placement="bottom-start" :options="customScheduleLimit" placeholder="请选择开始档期"></DatePicker>
              </p>
              <p class="m-t-5">
                <template v-if="item.type===1">
                  <span class="title">结束日期</span> <DatePicker size="small" transfer class="text-black" v-model="item.endDate" :editable="false" type="date"
                    style="width: 150px" placement="bottom-start" :options="customScheduleLimit" placeholder="请选择结束档期"></DatePicker>

                  <p class="m-t-5"><span class="title">发布天数</span><span class="text-orange m-l-5">{{getPublishDay(item)}}</span></p>
                  <Alert v-if="getPublishDay(item)===''" type="error" class="m-t-5">结束日期不能小于开始日期</Alert>
                </template>

                <template v-else-if="item.type===2">
                  <span class="title">发布时长 </span>
                  <Select v-model="item.period" size="small" style="width:150px">
                      <Option :value="1">一周（7天）</Option>
                      <Option :value="2">四周（28天）</Option>
                      <Option :value="3">十二周（84天）</Option>
                      <Option :value="4">半年（182天）</Option>
                      <Option :value="5">一年（364天）</Option>
                  </Select>
                  <p class="m-t-5"><span class="title">结束日期</span><span class="text-orange m-l-5">{{getPublishEndDate(item)}}</span></p>
                </template>

                <template v-else>
                  <span class="title">发布天数 </span>
                  <InputNumber v-model="item.publishDay" size="small" :min="1"></InputNumber> 天
                  <p class="m-t-5"><span class="title">结束日期</span><span class="text-orange m-l-5">{{getPublishEndDateByDay(item)}}</span></p>
                </template>

                <Alert v-if="checkRepeatDate(item, index, customSchduleList)" type="error">
                    <span slot="desc">
                      发布档期中包含重复时间段，请重新设置日期！
                    </span>
                </Alert>
              </p>
            </i-col>
            <i-col span="8" class="p-t-5">
              <RadioGroup v-model="item.type">
                  <Radio :label="1">默认</Radio>
                  <Radio :label="2">固定周期</Radio>
                  <Radio :label="3">自定义天数</Radio>
              </RadioGroup>
            </i-col>
            <i-col span="8">
              <Icon type="ios-trash" color="red" size="24" class="schedule-delete" @click="handleDeleteCustomSchedule(index)"/>
            </i-col>
            <i-col span="24"><Divider style="margin: 10px 0 5px 0;" /></i-col>
          </Row>
          <a @click="handleAddCustomSchedule"><Icon type="md-add" />添加新的档期</a>

        </div>
        <div slot="footer" >
          <Button class="btn-black m-l-8" size="small" @click="modalEditSchedule = false">取消</Button>
          <Button class="m-l-8" type="success" size="small" @click="handleSubmitChange" :disabled="disabledSubmit">提交更改</Button>
        </div>
    </Modal>
</template>

<script>
import { GetPrevSchedule, GetDateStr, GetPublishDay, ParseDate } from '@/utils/dateFormat'
import { updateSchedule } from '@/api/order/order'

export default {
  name: 'editSchedult',
  props: {
    orderBean: {
      type: Object,
      required: true
    },
    isShow: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      publisherId: this.$store.getters.token.userInfo.publisherId,
      companyId: this.$store.getters.token.userInfo.companyId,
      modalEditSchedule: false,
      standard: 1,
      schedules: [],
      chooseSchedules: [],
      scheduleTypes: [{ id: 1, name: '常规档期' }, { id: 2, name: '自定义档期' }],
      customSchduleList: [{
        startDate: '',
        endDate: '',
        type: 1, // 自定义类型
        period: 1, // 固定周期类型
        publishDay: 1
      }],
      dateRepeat: false, // 日期是否有重叠
      customScheduleLimit: {}
    }
  },
  created () {
    // 设置常规档期，并根据配置前移时间
    this.schedules = GetPrevSchedule(this.$store.getters.token.publisherSetting.advancePurchaseTime)

    this.initCustomOption()
  },
  computed: {
    disabledSubmit: function () {
      if (this.standard === 1) {
        return this.chooseSchedules.length === 0
      } else {
        let flag = true
        for (let index = 0; index < this.customSchduleList.length; index++) {
          if (this.customSchduleList[index].startDate !== '' && this.customSchduleList[index].endDate !== '' && GetPublishDay(this.customSchduleList[index].startDate, this.customSchduleList[index].endDate) !== '') {
            flag = this.checkRepeatDate(this.customSchduleList[index], index, this.customSchduleList)
            break
          }
        }

        return flag
      }
    }
  },
  methods: {
    getPublishDay (schedule) {
      if (schedule.startDate === '' || schedule.endDate === '') {
        return '-'
      } else {
        return GetPublishDay(schedule.startDate, schedule.endDate)
      }
    },
    getPublishEndDate (schedule) {
      if (schedule.startDate === '') {
        return '-'
      } else {
        let endDate = ''
        if (schedule.period === 1) { // 一周
          endDate = GetDateStr(schedule.startDate, 7)
        } else if (schedule.period === 2) { // 四周
          endDate = GetDateStr(schedule.startDate, 28)
        } else if (schedule.period === 3) { // 十二周
          endDate = GetDateStr(schedule.startDate, 84)
        } else if (schedule.period === 4) { // 半年
          endDate = GetDateStr(schedule.startDate, 182)
        } else if (schedule.period === 5) { // 一年
          endDate = GetDateStr(schedule.startDate, 364)
        }

        schedule.endDate = endDate
        return endDate
      }
    },
    getPublishEndDateByDay (schedule) {
      if (schedule.startDate === '') {
        return '-'
      } else {
        const endDate = GetDateStr(schedule.startDate, schedule.publishDay)
        schedule.endDate = endDate
        return endDate
      }
    },
    handleSubmitChange () {
      const orderschedules = []
      if (this.standard === 1) { // 常规档期
        this.chooseSchedules.forEach(res => {
          orderschedules.push({ startDate: GetDateStr(res, 1), endDate: GetDateStr(res, 14) })
        })
        // 档期从小到大排序
        orderschedules.sort(function (a, b) {
          return a.startDate > b.startDate ? 1 : -1
        })
      } else {
        this.customSchduleList.forEach(res => {
          if (res.startDate !== '' && res.endDate !== '') {
            orderschedules.push({ startDate: ParseDate(res.startDate), endDate: ParseDate(res.endDate) })
          }
        })
      }

      const changeBean = {
        orderId: this.orderBean.id,
        standard: this.standard,
        schedules: JSON.stringify(orderschedules)
      }
      updateSchedule(changeBean).then(res => {
        this.modalEditSchedule = false
        // 刷新父窗体的订单档期
        this.$emit('on-update-order')
      })
    },
    initCustomOption () {
      const _this = this
      this.customScheduleLimit = {
        disabledDate (date) {
          return date.valueOf() < Date.now() - (86400000 * 30 * _this.$store.getters.token.publisherSetting.advancePurchaseTime)
        }
      }
    },
    handleAddCustomSchedule () {
      this.customSchduleList.push({
        startDate: '',
        endDate: '',
        type: 1, // 自定义类型
        period: 1, // 固定周期类型
        publishDay: 1
      })
    },
    handleDeleteCustomSchedule (index) {
      this.customSchduleList.splice(index, 1)
    },
    checkRepeatDate (item, itemIndex, itemList) { // 验证自定义日期是否有重复
      // const isOverlap = (StartA, EndA, StartB, EndB) => {
      //   const getT = (t) => new Date(t)
      //   return !(getT(EndA) <= getT(StartB) || getT(StartA) >= getT(EndB))
      // }

      // if (itemList.length < 2) {
      //   return false
      // } else {
      //   // 循环验证是否重复
      //   let flag = false
      //   for (let index = 0; index < itemList.length; index++) {
      //     if (index !== itemIndex && itemList[index].startDate && itemList[index].endDate && item.startDate && item.endDate) {
      //       flag = isOverlap(item.startDate, item.endDate, itemList[index].startDate, itemList[index].endDate)
      //       if (flag) {
      //         break
      //       }
      //     }
      //   }

      //   return flag
      // }
      return false
    }

  },
  watch: {
    modalEditSchedule (val) {
      this.$emit('update:isShow', val)
    },
    isShow (val) {
      this.modalEditSchedule = val
      if (val) {
        // 刷新父窗体界面， 更新档期
        this.standard = this.orderBean.standard
        if (this.standard === 1) {
          this.chooseSchedules = []
          this.orderBean.orderScheduleList.forEach(element => {
            if (['01', '15'].includes(element.startDate.substr(8))) {
              this.chooseSchedules.push(element.startDate)
            }
          })
        } else {
          this.customSchduleList = []

          this.orderBean.orderScheduleList.forEach(element => {
            this.customSchduleList.push({
              startDate: element.startDate,
              endDate: element.endDate,
              type: 1, // 自定义类型
              period: 1, // 固定周期类型
              publishDay: 1
            })
          })
        }
      }
    }
  }
}
</script>
